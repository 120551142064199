import { z } from 'zod';

export const UserSchema = z.object({
  uid: z.string(),
  displayName: z.string().nullable().optional(),
  jobTitle: z.string().nullable().optional(),
  userLevel: z.string().nullable().optional(),
  email: z.string().nullable().optional(),
  photoURL: z.string().nullable().optional(),
  savedJobs: z.array(z.string()),
  appliedJobs: z.array(z.string()),
  createdAt: z.date(),
});

export type User = z.infer<typeof UserSchema>;
