import {
  faClipboard,
  faEllipsisVertical,
  faPencil,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import React, { useEffect, useRef, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { fetchUserByUID } from '../../api/firebase/fetchUser';
import AppliedJobs from '../../components/jobs/applied-jobs/applied-jobs.component';
import SavedJobs from '../../components/jobs/saved-jobs/saved-jobs.component';
import Sidebar from '../../components/sidebar/sidebar.component';
import Users from '../../components/users/users.component';
import { User } from '../../model/zod/user.zod';
import { AuthContext } from '../../services/auth-context.service';
import { useSafeContext } from '../../util/useSafeContext';
import './profile-page.styles.scss';

export default function Profile() {
  const navigate = useNavigate();
  const [currPage, setCurrPage] = useState('profile');
  const [openSidebar, setOpenSidebar] = useState<boolean>(true);
  const { user, updateData } = useSafeContext(AuthContext);
  const [displayedUser, setDisplayedUser] = useState<User | null>(null);
  const [openEditMenu, setOpenEditMenu] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const menuRef = useRef<HTMLDivElement>(null);
  const statusRef = useRef<HTMLDivElement>(null);

  // Protected Routes for users trying to access other profiles (except Admins)
  const { uid } = useParams<{ uid: string }>();
  const isAuthorized =
    (user && uid === user.uid) || user?.userLevel === 'admin'; // check if logged user is same as displayed user and/or is an admin

  if (user && !isAuthorized) {
    return <Navigate to="/" />;
  }

  useEffect(() => {
    if (uid) {
      fetchUserByUID(uid)
        .then((res) => {
          setDisplayedUser(res);
          setName(res?.displayName ?? '');
          setTitle(res?.jobTitle ?? '');
        })
        .catch((error) => {
          console.error('Error fetching user by UID: ', error);
        });
    }
  }, [uid, updateData]);

  // method to update user metadata
  const handleSave = async () => {
    const payload: Partial<User> = {
      displayName: name,
      jobTitle: title,
    };
    if (uid) {
      try {
        await updateData(payload, uid);
        setEditMode(false);
        setOpenEditMenu(false);
        showStatusMessage('Saved!');
      } catch (error) {
        showStatusMessage('Failed to update user data: ' + error);
      }
    }
  };

  // copies user ID to clipboard and sends status message
  const copyUIDtoClipboard = () => {
    if (displayedUser) {
      navigator.clipboard
        .writeText(displayedUser.uid)
        .then(() => {
          showStatusMessage('Copied!');
        })
        .catch((error) => {
          showStatusMessage('Error copying UID, ' + error);
        });
    }
  };

  // shows a slide-in div w/ arg status message
  const showStatusMessage = (text: string) => {
    if (statusRef.current) {
      statusRef.current.innerText = text;
      statusRef.current.style.visibility = 'visible';
      statusRef.current.style.top = '10px';

      setTimeout(() => {
        if (statusRef.current) {
          statusRef.current.style.visibility = 'hidden';
          statusRef.current.style.top = '-50px';
        }
      }, 2000);
    }
  };

  const renderPage = () => {
    switch (currPage) {
      case 'users':
        return <Users />;
      case 'saved':
        return <SavedJobs uid={uid} />;
      case 'applied':
        return <AppliedJobs uid={uid} />;
      default:
        return <SavedJobs uid={uid} />;
    }
  };

  // General useEffect for handling clicks outside the dropdown
  useEffect(() => {
    const handleDocumentClick = (e: MouseEvent) => {
      const menu = menuRef.current as HTMLDivElement | null;
      if (
        menu &&
        e.target &&
        !(e.target as Element).closest('.editMenuBtn') &&
        !(e.target as Element).closest('.editMenuWrapper')
      ) {
        setOpenEditMenu(false);
      }
    };

    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  useEffect(() => {
    switch (currPage) {
      case 'dashboard':
        navigate('/');
        break;
      case 'users':
        navigate('/users');
        break;
      case 'profile':
        navigate(`/users/${user?.uid || ''}`);
        break;
      default:
        console.warn('No matching route for:', currPage);
    }
    console.log(currPage);
  }, [currPage, navigate, user?.uid]);

  const sidebarProps = {
    currPage,
    setCurrPage,
    isOpen: openSidebar,
    toggleSidebar: () => setOpenSidebar(!openSidebar),
  };

  return (
    <section className="profile">
      <Sidebar {...sidebarProps} />
      {currPage !== 'users' && (
        <div className="userCardWrapper">
          <header className="profile-header">
            <p>Profile</p>
          </header>
          <div className="profileCard">
            {displayedUser?.photoURL && displayedUser.photoURL !== '' ? (
              <img
                src={displayedUser.photoURL}
                className="profileImg"
                alt="profile img"
              />
            ) : (
              <img
                src="/profile.png"
                className="profileImg"
                alt="profile img"></img>
            )}
            {displayedUser?.displayName &&
              (!editMode ? (
                <h2 className="profileTitle">{displayedUser.displayName}</h2>
              ) : (
                <input
                  type="text"
                  className="displayNameInput"
                  placeholder="Display Name"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
              ))}
            {!editMode ? (
              displayedUser?.jobTitle && (
                <h3 className="profileSubtitle">{displayedUser.jobTitle}</h3>
              )
            ) : (
              <input
                type="text"
                className="jobTitleInput"
                placeholder="Job Title"
                onChange={(e) => setTitle(e.target.value)}
                value={title}
              />
            )}
            {displayedUser?.email && (
              <h3 className="profileSubtitle">{displayedUser.email}</h3>
            )}
            <div
              className="editMenuBtn"
              ref={menuRef}
              onClick={() => setOpenEditMenu(!openEditMenu)}>
              {!editMode ? (
                <div className="editMenuWrapper">
                  <FontAwesomeIcon
                    icon={faEllipsisVertical}
                    className="optionIcon"
                  />
                  <div className={'editMenu ' + (openEditMenu ? 'open' : '')}>
                    {isAuthorized ? (
                      <div
                        className="editOption"
                        onClick={() => setEditMode(!editMode)}>
                        <FontAwesomeIcon
                          icon={faPencil}
                          className="optionIcon"
                        />
                        <span>Edit profile</span>
                      </div>
                    ) : (
                      ''
                    )}
                    <div className="editOption" onClick={copyUIDtoClipboard}>
                      <FontAwesomeIcon
                        icon={faClipboard}
                        className="optionIcon"
                      />
                      <span>Copy UID</span>
                    </div>
                  </div>
                </div>
              ) : (
                <button className="saveBtn" onClick={handleSave}>
                  Save
                </button>
              )}
            </div>
          </div>
          <div className="listSwitcher">
            <hr className="categorySeparator" />
            <div
              className={'switchBtn ' + (currPage === 'saved' ? 'active' : '')}
              onClick={() => setCurrPage('saved')}>
              Saved Jobs
              <p
                className="total-saved-jobs"
                style={{
                  margin: 0,
                }}>
                {displayedUser?.savedJobs?.length ?? 0}
              </p>
            </div>
            <div
              className={
                'switchBtn ' + (currPage === 'applied' ? 'active' : '')
              }
              onClick={() => setCurrPage('applied')}>
              Applied Jobs
              <p
                className="total-applied-jobs"
                style={{
                  margin: 0,
                }}>
                {displayedUser?.appliedJobs?.length ?? 0}
              </p>
            </div>
            {process.env.NODE_ENV === 'development' && (
              <div className="switchBtn">Job Matches</div>
            )}
          </div>
        </div>
      )}
      <div className="separator"></div>
      <div className="jobListWrapper">{renderPage()}</div>
      <div className="statusMessage" ref={statusRef}></div>
    </section>
  );
}
