import React, { useEffect, useMemo, useState } from 'react';

import { fetchAllUsers } from '../../api/firebase/fetchAllUsers';
import Sidebar from '../../components/sidebar/sidebar.component';
import { User } from '../../model/zod/user.zod';
import UserFilters from './user-filters/user-filters.component';
import UserListing from './user-listing/user-listing.component';
import './users.styles.scss';

export default function Users() {
  const [users, setUsers] = useState<User[]>([]);
  const [openSidebar, setOpenSidebar] = useState<boolean>(true);
  const [currPage, setCurrPage] = useState('users');

  useEffect(() => {
    fetchAllUsers().then((res) => {
      if (res) {
        setUsers(res);
      }
    });
  }, []);

  // Map function to output each user
  const renderUsers = useMemo(() => {
    // Users component using job list pagination (needs refactor)
    // <>
    //   <Pagination
    //     queryResults={users}
    //     renderItem={(user: User, index: number) => (
    //       <UserListing key={index} user={user} />
    //     )}
    //     itemsPerPage={30}
    //     onScroll={(ref) => {
    //       if (ref.current) {
    //         setScrolled(ref.current.scrollTop > 50);
    //       }
    //     }}
    //     scrolled={scrolled}
    //     scope={'users'}
    //   />
    // </>
    return users.map((user) => <UserListing key={user.uid} user={user} />);
  }, [users]);

  const sidebarProps = {
    currPage,
    setCurrPage,
    isOpen: openSidebar,
    toggleSidebar: () => setOpenSidebar(!openSidebar),
  };

  return (
    <section className="usersMainContainer">
      <Sidebar {...sidebarProps} />
      <div className="usersContainer">
        <div className="jobBoardUserLogoContainer">
          <p className="usersTitle"> Interns </p>
          <img
            src="../../../logo_powered_by.png"
            className="mainDashLogo"></img>
        </div>
        <UserFilters />
        <div className="usersWrapper">{users && renderUsers}</div>
      </div>
    </section>
  );
}
