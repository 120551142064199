import * as React from 'react';

const UsersIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={18}
    height={14}
    viewBox="0 0 18 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.7583 12.2137C15.8037 12.2197 15.8492 12.2259 15.8946 12.2322L16.1223 12.2642C17.1138 12.4033 18.0009 11.6476 18.0009 10.6638V9.91304C18.0009 9.20945 17.5506 8.58182 16.876 8.34518C16.0965 8.07172 15.2918 7.89368 14.4794 7.81107C15.3269 8.4238 15.8526 9.41603 15.8526 10.4985V11.4307C15.8526 11.7014 15.8198 11.9635 15.7583 12.2137ZM12.3232 6.81219C12.6611 6.9517 13.0322 7.0288 13.4216 7.0288C14.988 7.0288 16.2578 5.78152 16.2578 4.24292C16.2578 2.70431 14.988 1.45703 13.4216 1.45703C13.3751 1.45703 13.3288 1.45813 13.2828 1.46031C13.5676 2.06694 13.7268 2.74428 13.7268 3.4588C13.7268 4.77133 13.1897 5.9584 12.3232 6.81219Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.2427 12.2137C2.19723 12.2197 2.15178 12.2259 2.10635 12.2322L1.87863 12.2642C0.887155 12.4033 9.82285e-05 11.6476 9.82285e-05 10.6638V9.91304C9.82285e-05 9.20945 0.450385 8.58182 1.12495 8.34518C1.90445 8.07172 2.7092 7.89368 3.52157 7.81107C2.67405 8.4238 2.14838 9.41603 2.14838 10.4985V11.4307C2.14838 11.7014 2.18113 11.9635 2.2427 12.2137ZM5.67773 6.81219C5.33992 6.9517 4.96881 7.0288 4.57937 7.0288C3.013 7.0288 1.7432 5.78152 1.7432 4.24292C1.7432 2.70431 3.013 1.45703 4.57937 1.45703C4.62591 1.45703 4.67218 1.45813 4.71817 1.46031C4.43334 2.06694 4.27415 2.74428 4.27415 3.4588C4.27415 4.77133 4.81129 5.9584 5.67773 6.81219Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.478 3.45881C12.478 1.54856 10.9294 0 9.01915 0C7.1089 0 5.56034 1.54856 5.56034 3.45881C5.56034 5.36906 7.1089 6.91762 9.01915 6.91762C10.9294 6.91762 12.478 5.36906 12.478 3.45881Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.80636 8.55196C7.53062 7.57901 10.5077 7.57901 13.2319 8.55196C14.0546 8.84576 14.6037 9.625 14.6037 10.4985V11.4307C14.6037 12.6521 13.5219 13.5903 12.3128 13.4176L12.0351 13.3779C10.0346 13.0922 8.00368 13.0922 6.00322 13.3779L5.72551 13.4176C4.51637 13.5903 3.43457 12.6521 3.43457 11.4307V10.4985C3.43457 9.625 3.98371 8.84576 4.80636 8.55196Z"
      fill="currentColor"
    />
  </svg>
);

export default UsersIcon;
