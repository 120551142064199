import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { doc, updateDoc } from 'firebase/firestore';

import React, { useEffect, useState } from 'react';

import { updateSavedJobs } from '../../../api/firebase/service';
import firebaseApp from '../../../firebaseConfig';
import { Job } from '../../../model';
import { AuthContext } from '../../../services/auth-context.service';
import { LoggerContext } from '../../../services/logger-context.service';
import { useSafeContext } from '../../../util/useSafeContext';
import StarComponent from '../../star/star';
import './job-listing.styles.scss';

interface JobListingProps {
  job: Job;
  uid?: string;
}

const JobListing: React.FC<JobListingProps> = ({ job, uid }) => {
  const { user } = useSafeContext(AuthContext);
  const isCurrentUserDisplayed = uid == null || user?.uid === uid;
  const logger = useSafeContext(LoggerContext);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const isJobFavorited = (jobId: string) => user?.savedJobs.includes(jobId);
  const isJobApplied = (jobId: string) => user?.appliedJobs.includes(jobId);
  const [isFavorited, setIsFavorited] = useState<boolean>(
    isJobFavorited(job.id) ?? false,
  );
  const [isApplied, setIsApplied] = useState<boolean>(
    isJobApplied(job.id) ?? false,
  );

  useEffect(() => {
    if (!isCurrentUserDisplayed) {
      return;
    }
    setIsFavorited(isJobFavorited(job.id) ?? false);
    setIsApplied(isJobApplied(job.id) ?? false);
  }, [user?.savedJobs, user?.appliedJobs, job.id, uid]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const toggleStarFill = async (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>,
  ) => {
    event.stopPropagation();

    let savedJobs = user?.savedJobs ?? [];
    if (isFavorited) {
      savedJobs = savedJobs.filter((savedJob: string) => job.id !== savedJob);
    } else {
      savedJobs.push(job.id);
    }

    setIsFavorited(!isFavorited);

    if (user != null) {
      try {
        const userDocRef = doc(firebaseApp.db, 'users', user.uid);
        await updateDoc(userDocRef, {
          savedJobs,
        });
        await updateSavedJobs(job);
      } catch (error) {
        logger.error("Failed to update user's applied jobs:", error);
      }
    }
  };

  const onApplied = async (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.stopPropagation();

    let appliedJobs = user?.appliedJobs ?? [];
    if (isApplied) {
      appliedJobs = appliedJobs.filter(
        (appliedJob: string) => job.id !== appliedJob,
      );
    } else {
      appliedJobs.push(job.id);
    }

    setIsApplied(!isApplied);

    if (user != null) {
      try {
        const userDocRef = doc(firebaseApp.db, 'users', user.uid);
        await updateDoc(userDocRef, {
          appliedJobs,
        });
        await updateSavedJobs(job);
      } catch (error) {
        logger.error("Failed to update user's applied jobs:", error);
      }
    }
  };

  const truncateText = (text: string, maxLength: number) => {
    return isExpanded || text.length <= maxLength
      ? text
      : text.substring(0, maxLength) + '...';
  };

  return (
    <div className="job-container">
      <div className="job-listing">
        <div
          className="title-container"
          onClick={toggleExpand}
          style={{ cursor: 'pointer' }}>
          <div className="short-desc">
            <div className="title">
              <h3 className="job-listing-title">
                {truncateText(job.title, 45)}
              </h3>
              {isCurrentUserDisplayed && (
                <StarComponent
                  isStarFilled={isFavorited}
                  toggleStarFill={toggleStarFill}
                />
              )}
            </div>
            <div className="location">
              <h4>
                {' '}
                {truncateText(job.company, 30)} -{' '}
                {truncateText(job.location, 30)}
              </h4>
            </div>
            <div className="time-commitment">
              <h4>{truncateText(job.typeOfWork, 40)}</h4>
            </div>
            <h5 className="toggleDetailsText">
              {isExpanded ? 'Close Details' : 'View Details'}
            </h5>
          </div>
        </div>
        {isExpanded && (
          <div className="job-details">
            <div className="mobile-details">
              <p>
                <strong>Location:</strong> {truncateText(job.location, 40)}
              </p>
              <p>
                <strong>{job.timeCommitment}</strong>
              </p>
            </div>
            <p>
              <strong>Company:</strong> {job.company}
            </p>
            {job.typeOfWork && (
              <p>
                <strong>Work Environment:</strong> {job.typeOfWork}
              </p>
            )}
            {job.pay && (
              <p>
                <strong>Pay:</strong> {job.pay}
              </p>
            )}
            <strong>Description:</strong>
            <ul>
              {job.description.map((item, index) => (
                <li key={index}>
                  <strong>{item.title}:</strong> {item.content}
                  <br />
                  <br />
                </li>
              ))}
            </ul>
            <div className="job-link-applied">
              <a href={job.link} target="_blank" rel="noopener noreferrer">
                Link to Apply
              </a>
              {isCurrentUserDisplayed && (
                <div
                  className={`applied-toggable ${isApplied ? 'clicked' : ''}`}
                  onClick={onApplied}>
                  <FontAwesomeIcon fa-solid="true" icon={faCircleCheck} />
                  <span className="applied-text">Applied</span>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default JobListing;
