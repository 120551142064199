import React from 'react';

import './page-not-found-page.styles.scss';

const PageNotFound: React.FC = () => {
  return (
    <div className="page-not-found">
      <h1>404 - Page Not Found</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
      <button onClick={() => window.history.back()}>Go Back</button>
    </div>
  );
};

export default PageNotFound;
