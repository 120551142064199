import React, { useState } from 'react';

import GoogleSignInBtn from '../../components/google-sign-in-button/google-sign-in-button.component';
import { AuthContext } from '../../services/auth-context.service';
import { useSafeContext } from '../../util/useSafeContext';
import './login-page.styles.scss';

const Login: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState('');
  // Sign in Providers
  const { signInWithGoogle } = useSafeContext(AuthContext);

  const handleGoogleSignIn = async () => {
    try {
      await signInWithGoogle();
    } catch (error: unknown) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
      }
    }
  };

  return (
    <div className="loginPageWrapper">
      <div className="welcomeSection">
        <div className="welcomeText">
          <h3 className="welcomeSubtitle">Welcome to</h3>
          <h2 className="welcomeTitle">Career Harvest</h2>
        </div>
      </div>
      <div className="loginSection">
        <div className="logoSection">
          <img className="poweredLogo" src="/logo_powered_by.png" alt="logo" />
        </div>
        <div className="loginContent">
          <div className="loginBox">
            <h3 className="loginHeading">Login</h3>
            <div className="googleSignInBtn" onClick={handleGoogleSignIn}>
              <GoogleSignInBtn />
            </div>
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
