import { collection, getDocs } from 'firebase/firestore';

import firebaseApp from '../../firebaseConfig';
import { UserSchema } from '../../model/zod/user.zod';

export const fetchAllUsers = async () => {
  try {
    const usersCollectionRef = collection(firebaseApp.db, 'users');
    const querySnapshot = await getDocs(usersCollectionRef);
    const usersList = querySnapshot.docs.map((doc) => {
      const userData = doc.data();
      return UserSchema.parse({
        ...userData,
        createdAt: userData.createdAt.toDate(),
      });
    });
    return usersList;
  } catch (error) {
    console.error('error fetching users: ', error);
    return null;
  }
};
