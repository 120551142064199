import {
  // faCircleXmark,
  faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Job } from '../../../model';
import { JobsContext } from '../../../services/jobs-context.service';
import debounce from '../../../util/debounce';
import { useSafeContext } from '../../../util/useSafeContext';
import Pagination from '../../pagination/pagination.component';
import SearchBar from '../../search-bar/search-bar-component';
import Spinner from '../../spinner/spinner.component';
import Filters from '../job-filters/job-filters.component';
import JobListing from '../job-listing/job-listing.component';
import './search-jobs.styles.scss';

// Helper function to parse a pay range string into numerical min and max values
function parsePayRange(rangeStr: string) {
  const numbers = rangeStr.match(/\d+\.?\d*/g);
  if (numbers) {
    return {
      min: numbers[0],
      max: numbers.length > 1 ? numbers[1] : Infinity, // Assume "and up" as Infinity
    };
  }
  return {
    min: 0,
    max: 0,
  };
}

// Helper function to extract the minimum and maximum pay from a job pay string
function parseJobPay(jobPayStr: string) {
  const numbers = jobPayStr.match(/\d+\.?\d*/g);
  if (numbers) {
    return {
      min: numbers[0],
      max: numbers.length > 1 ? numbers[1] : numbers[0], // Single value means min and max are the same
    };
  }
  return {
    min: 0,
    max: Infinity, // jobs with no pay listed are shown for any pay range
  };
}

// Main function to check if job pay falls within any pay range in the filter
function isJobPayInRange(incFilters: { pay: string[] }, jobPayStr: string) {
  if (incFilters.pay.length > 0) {
    const jobPay = parseJobPay(jobPayStr);

    // Check if any range in the filter includes the job pay range
    const isInRange = incFilters.pay.some((rangeStr) => {
      const range = parsePayRange(rangeStr);
      return (
        (jobPay.min >= range.min && jobPay.min <= range.max) ||
        (jobPay.max >= range.min && jobPay.max <= range.max)
      );
    });

    return !isInRange; // Return false if job pay is not within any range
  }
  return true; // If no filters, the job is assumed to be in range
}

const EMPTY_FILTERS = {
  location: [],
  jobCategory: [],
  typeOfWork: [],
  education: [],
  pay: [],
};

const SearchComponent = () => {
  const searchInput = useRef<HTMLInputElement>(null);
  const { jobs, isLoading } = useSafeContext(JobsContext);
  const [searchQuery, setSearchQuery] = useState('');
  const [queryResults, setQueryResults] = useState<Job[]>([]);
  const [filters, setFilters] = useState<{ [key: string]: string[] }>(
    EMPTY_FILTERS,
  );
  const [scrolled, setScrolled] = useState(false);
  const sectionRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const delay = searchQuery.length > 3 ? 150 : 300;
    const timeOutId = setTimeout(() => {
      handleSearch();
    }, delay);
    return () => clearTimeout(timeOutId);
  }, [searchQuery, filters, jobs]);

  const applyAllFilters = (
    jobsQuery: Job[],
    incFilters: Record<string, string[]>,
  ) => {
    jobsQuery = jobsQuery.filter((job) => {
      // If location filter array is not empty, check if any word in the filter array is included in the job location
      if (incFilters.location.length > 0) {
        if (
          !incFilters.location.some((loc: string) => job.location.includes(loc))
        ) {
          return false;
        }
      }

      // If typeOfWork filter array is not empty, check if any word in the filter array is included in the job timeCommitment
      if (incFilters.typeOfWork.length > 0) {
        if (
          !incFilters.typeOfWork.some(
            (type: string) =>
              job.timeCommitment.includes(type) ||
              job.typeOfWork.includes(type),
          )
        ) {
          return false; // Exclude job if none of the words in the filter array are included in the job timeCommitment
        }
      }

      // If education filter array is not empty, check if any word in the filter array is included in the job description
      if (incFilters.education.length > 0) {
        let included = false;
        incFilters.education.some((option: string) =>
          Object.values(job.description).some(
            (item: { [key: string]: string }) => {
              if (item.content.includes(option)) {
                included = true;
              }
              return included;
            },
          ),
        );
        return included;
      }

      // if pay filter array is not empty, check if the range of the job pay is included in the filter array
      if (incFilters.pay.length > 0) {
        // for each pay range in the filter array, check if the job pay is within the range
        if (!isJobPayInRange({ pay: incFilters.pay }, job.pay) === false) {
          return false; // Exclude job if none of the pay ranges in the filter array include the job pay
        }
      }

      // If jobCategory filter array is not empty, check if job category matches job category
      if (incFilters.jobCategory.length > 0) {
        if (
          !incFilters.jobCategory.some((option: string) =>
            job.jobCategory.includes(option),
          )
        ) {
          return false;
        }
      }

      return true; // Include job if it passes all filter conditions
    });
    setQueryResults([...jobsQuery]);
  };

  const isFiltersEmpty = (incFilters: Record<string, string[]>): boolean => {
    return Object.values(incFilters).every(
      (arr) => Array.isArray(arr) && arr.length === 0,
    );
  };

  const handleSearch = useCallback(
    debounce(() => {
      const lowerCaseQuery = searchQuery.toLowerCase();
      const titleResults = jobs.filter((job) =>
        job.title.toLowerCase().includes(lowerCaseQuery),
      );
      const companyResults = jobs.filter(
        (job) =>
          job.company.toLowerCase().includes(lowerCaseQuery) &&
          !titleResults.includes(job),
      );
      applyAllFilters([...titleResults, ...companyResults], filters);
    }, 500),
    [jobs, searchQuery, queryResults, filters],
  );

  // hitting the 'enter' key while the search bar is focused will trigger a search
  useEffect(() => {
    const inputElement = searchInput.current;

    const handleKeyPressOnInput = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleSearch();
      }
    };

    inputElement?.addEventListener('keydown', handleKeyPressOnInput);

    return () => {
      inputElement?.removeEventListener('keydown', handleKeyPressOnInput);
    };
  }, [handleSearch, searchInput]);

  const clearSearch = useCallback(() => {
    setSearchQuery('');
    searchInput.current?.focus();
  }, [searchQuery]);

  const renderSearchResults = useMemo(() => {
    return (
      <>
        {isLoading ? (
          <Spinner size={50} color={'#bde3d0'} />
        ) : (
          <Pagination
            queryResults={
              searchQuery.length === 0 && isFiltersEmpty(filters)
                ? jobs
                : queryResults
            }
            renderItem={(job: Job, index: number) => (
              <JobListing key={index} job={job} />
            )}
            onScroll={(ref) => {
              if (ref.current) {
                setScrolled(ref.current.scrollTop > 50);
              }
            }}
            scrolled={scrolled}
          />
        )}
      </>
    );
  }, [queryResults]);

  return (
    <>
      <section
        className={'searchContainer ' + (scrolled ? 'scrolled' : '')}
        ref={sectionRef}>
        <div
          className="searchBarContainer"
          onClick={() => searchInput.current && searchInput.current.focus()}>
          <span className="search-icon">
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          </span>
          <SearchBar
            searchQuery={searchQuery}
            onSearchQueryChange={setSearchQuery}
            clearSearch={clearSearch}
          />
        </div>
        <Filters
          onFilter={(filterParams: { [key: string]: string[] }) => {
            setFilters({ ...filters, ...filterParams });
          }}
          onClearFilters={() => {
            setFilters(EMPTY_FILTERS);
          }}
        />
        <p className="jobCount">
          {isFiltersEmpty(filters) && searchQuery.length === 0
            ? jobs.length
            : queryResults.length}{' '}
          Jobs Found
        </p>
      </section>
      {renderSearchResults}
    </>
  );
};

export default SearchComponent;
