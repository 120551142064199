import * as React from 'react';

const CloseIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={13}
    height={13}
    viewBox="0 0 13 13"
    fill="black" // Set fill color to black
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M9.288 13L5.742 7.33H5.4L0.504 0.291999H3.87L7.362 5.62H7.704L12.6 13H9.288ZM0.27 13L4.968 6.196L7.146 7.42L3.492 13H0.27ZM7.812 7.006L5.634 5.782L9.162 0.291999H12.384L7.812 7.006Z"
      fill="black"
    />
  </svg>
);

export default CloseIcon;
