import { useQuery } from '@tanstack/react-query';

import React, { useCallback, useEffect, useRef, useState } from 'react';

import Spinner from '../spinner/spinner.component';
import './pagination.styles.scss';

interface PaginationProps<T> {
  ids: string[];
  queryKey: string;
  renderItem: (item: T, index: number) => JSX.Element;
  fetchData: (dataIdsSubset: string[]) => Promise<T[]>;
  itemsPerPage?: number;
}

const ServerSidedPagination = <T,>({
  ids,
  queryKey,
  renderItem,
  fetchData,
  itemsPerPage = 6,
}: PaginationProps<T>) => {
  const [currentPage, setCurrentPage] = useState(1);
  const topRef = useRef<HTMLDivElement>(null);

  const queryFn = useCallback(async () => {
    if (ids.length === 0) return [];
    const startIdx = (currentPage - 1) * itemsPerPage;
    const endIdx = startIdx + itemsPerPage;
    const subset = ids.slice(startIdx, endIdx);
    return await fetchData(subset);
  }, [currentPage, ids, fetchData, itemsPerPage]);

  const { data, isLoading, isError } = useQuery({
    queryKey: [queryKey, currentPage, ids],
    queryFn,
  });

  // Adjust currentPage if it exceeds the total pages
  useEffect(() => {
    const totalPages = Math.ceil(ids.length / itemsPerPage);
    if (currentPage > totalPages) {
      setCurrentPage(totalPages || 1);
    }
  }, [ids, itemsPerPage, currentPage]);

  // Every time page changes, scroll to top
  useEffect(() => {
    topRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [currentPage]);

  const totalPages = Math.ceil(ids.length / itemsPerPage);

  const handleNext = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrev = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handlePageClick = (page: number) => {
    setCurrentPage(page);
  };

  const renderPageNumbers = () => {
    const pages = [];
    const pagesPerGroup = 3;
    const currentGroup = Math.ceil(currentPage / pagesPerGroup);

    const startPage = (currentGroup - 1) * pagesPerGroup + 1;
    const endPage = Math.min(startPage + pagesPerGroup - 1, totalPages);

    if (startPage > 1) {
      pages.push(
        <button
          key="prevDots"
          className="paginationEllipsis"
          onClick={() => handlePageClick(startPage - 1)}>
          ...
        </button>,
      );
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          className={`paginationButton ${i === currentPage ? 'active' : ''}`}
          onClick={() => handlePageClick(i)}
          disabled={i === currentPage}>
          {i}
        </button>,
      );
    }

    if (endPage < totalPages) {
      pages.push(
        <button
          key="nextDots"
          className="paginationEllipsis"
          onClick={() => handlePageClick(endPage + 1)}>
          ...
        </button>,
      );
    }

    return pages;
  };

  const renderPaginations = () => {
    return (
      <div className="paginationControls">
        <button
          onClick={handlePrev}
          disabled={currentPage === 1}
          className="paginationButton">
          Prev
        </button>
        {renderPageNumbers()}
        <button
          onClick={handleNext}
          disabled={currentPage === totalPages}
          className="paginationButton">
          Next
        </button>
      </div>
    );
  };

  function RenderList() {
    if (!ids) {
      return null;
    } else if (isError) {
      return <p>Error fetching data.</p>;
    } else if (isLoading) {
      return <Spinner size={25} color={'#bde3d0'} />;
    } else if (ids.length === 0) {
      return <p>There is nothing to see here....</p>;
    } else {
      return (
        data && (
          <>
            <div className="queryList" ref={topRef}>
              {data.map(renderItem)}
            </div>
            {renderPaginations()}
          </>
        )
      );
    }
  }

  return <div className="paginationContainer">{RenderList()}</div>;
};

export default ServerSidedPagination;
