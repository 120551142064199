import {
  GoogleAuthProvider,
  UserCredential,
  browserPopupRedirectResolver,
  signInWithPopup,
} from '@firebase/auth';
import { doc, getDoc, onSnapshot, setDoc } from 'firebase/firestore';

import React, { ReactNode, createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { fetchUserByUID } from '../api/firebase/fetchUser';
import firebaseApp from '../firebaseConfig';
import { User, UserSchema } from '../model/zod/user.zod';
import { useSafeContext } from '../util/useSafeContext';
import { LoggerContext } from './logger-context.service';

interface AuthContextType {
  user: User | undefined;
  isAuthenticated: boolean;
  isLoading: boolean;
  logout: () => void;
  signInWithGoogle: () => Promise<void>;
  updateData: (newData: Partial<User>, uid: string) => Promise<void>;
}

export const AuthContext = createContext<AuthContextType | undefined>(
  undefined,
);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<User>();
  const [isLoading, setIsLoading] = useState(true);
  const logger = useSafeContext(LoggerContext);
  const navigate = useNavigate();

  // listens the firestore DB for any user data changes
  useEffect(() => {
    let unsubscribeSnapshot: (() => void) | null = null;

    const unsubscribeAuth = firebaseApp.auth.onAuthStateChanged(
      (firebaseUser) => {
        if (firebaseUser) {
          const userRef = doc(firebaseApp.db, 'users', firebaseUser.uid);
          unsubscribeSnapshot = onSnapshot(
            userRef,
            (docSnapshot) => {
              if (docSnapshot.exists()) {
                const userData = docSnapshot.data();
                setUser(
                  UserSchema.parse({
                    ...userData,
                    createdAt: userData.createdAt.toDate(),
                  }),
                );
                setIsAuthenticated(true);
              } else {
                logger.warn(
                  `No such document for user ID: ${firebaseUser.uid}`,
                );
              }
              setIsLoading(false);
            },
            (error) => {
              logger.error('Error fetching user data:', error);
              setIsLoading(false);
            },
          );
        } else {
          setIsAuthenticated(false);
          setUser(undefined);
          setIsLoading(false);
        }
      },
    );

    // Clean up both auth listener and snapshot listener
    return () => {
      unsubscribeAuth();
      if (unsubscribeSnapshot) unsubscribeSnapshot(); // Check if it's defined before calling
    };
  }, []);

  const logout = () => {
    firebaseApp.auth
      .signOut()
      .then(() => {
        setIsAuthenticated(false);
        setUser(undefined);
        navigate('/login');
      })
      .catch((error) => {
        logger.error('Sign out error:', error);
      });
  };

  const signInWithGoogle = async () => {
    const provider = await new GoogleAuthProvider();
    try {
      const result: UserCredential = await signInWithPopup(
        firebaseApp.auth,
        provider,
        browserPopupRedirectResolver,
      );
      const googleUser = result.user;
      const { displayName, email, photoURL } = googleUser;
      const userRef = doc(firebaseApp.db, 'users', result.user.uid);
      const userSnap = await getDoc(userRef);

      if (!userSnap.exists()) {
        const newUser = UserSchema.parse({
          uid: result.user.uid,
          displayName,
          jobTitle: '',
          userLevel: '',
          email,
          photoURL,
          appliedJobs: [],
          savedJobs: [],
          createdAt: new Date(),
        });
        await setDoc(userRef, newUser);
        setUser(newUser);
      } else {
        const userData = userSnap.data();
        const parsedUser = UserSchema.parse({
          ...userData,
          createdAt: userData.createdAt.toDate(),
        });

        if (parsedUser.photoURL !== photoURL) {
          parsedUser.photoURL = photoURL;
          updateData(parsedUser, parsedUser.uid);
        }

        setUser(parsedUser);
      }

      setIsAuthenticated(true);
      navigate('/');
    } catch (error: unknown) {
      // TODO: Make this an error some modal / toast message
      alert(`Google Authentication Failed: ${error}`);
    }
  };

  const updateData = async (newData: Partial<User>, uid: string) => {
    const modUser = await fetchUserByUID(uid);
    const userRef = doc(firebaseApp.db, 'users', uid);
    const updatedUser = {
      ...modUser,
      ...newData,
    };

    await setDoc(userRef, updatedUser, { merge: true });

    if (uid === user?.uid) {
      setUser(UserSchema.parse(updatedUser));
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated,
        logout,
        signInWithGoogle,
        updateData,
        isLoading,
      }}>
      {children}
    </AuthContext.Provider>
  );
};
