import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import AppRoutes from './routes';
import { AuthProvider } from './services/auth-context.service';
import { LoggerProvider } from './services/logger-context.service';

const queryClient = new QueryClient({});

function App() {
  return (
    <LoggerProvider>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <AppRoutes />
          </AuthProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </LoggerProvider>
  );
}

export default App;
