import React from 'react';
import { useNavigate } from 'react-router-dom';

import { User } from '../../../model/zod/user.zod';
import './user-listing.styles.scss';

interface UserListingProps {
  user: User;
}

const UserListing: React.FC<UserListingProps> = ({ user }) => {
  const navigate = useNavigate();
  const handleUserClick = () => {
    navigate(`/users/${user.uid}`);
  };

  return (
    <div className="userCard" onClick={handleUserClick}>
      {user?.photoURL && user.photoURL !== '' ? (
        <img src={user.photoURL} className="profileImg" alt="profile img" />
      ) : (
        <img src="/profile.png" className="profileImg" alt="profile img"></img>
      )}
      <div className="userDetails">
        {user?.displayName && (
          <div className="name">
            {user?.displayName}
            {user?.userLevel && user.userLevel === 'admin' && (
              <span className="userLevel">{user.userLevel}</span>
            )}
          </div>
        )}
        {user?.jobTitle && <div className="jobTitle">{user?.jobTitle}</div>}
      </div>
    </div>
  );
};

export default UserListing;
