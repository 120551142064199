import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Footer from './components/footer/footer';
import Users from './components/users/users.component';
import Login from './pages/login-page/login-page';
import Main from './pages/main-page/main-page';
import PageNotFound from './pages/page-not-found/page-not-found-page';
import Profile from './pages/profile-page/profile-page';
import { JobsContextProvider } from './services/jobs-context.service';
import ProtectedRoute from './util/protectedRoute';

const AppRoutes: React.FC = () => (
  <Routes>
    <Route path="/login" element={<Login />} />
    <Route
      path="/"
      element={
        <ProtectedRoute
          element={
            <JobsContextProvider>
              <Main />
              <Footer />
            </JobsContextProvider>
          }
        />
      }
    />
    <Route path="/users" element={<ProtectedRoute element={<Users />} />} />
    <Route
      path="/users/:uid"
      element={
        <ProtectedRoute
          element={
            <JobsContextProvider>
              <Profile />
              {/* <Footer /> */}
            </JobsContextProvider>
          }
        />
      }
    />

    <Route path="/404" element={<PageNotFound />} />
    <Route path="*" element={<Navigate to="/404" replace />} />
  </Routes>
);

export default AppRoutes;
