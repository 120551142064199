import React from 'react';

import { JobBoardProps } from '../../../pages/main-page/main-page';
import SearchComponent from '../search-jobs/search-jobs.component';
import './job-board.styles.scss';

export default function JobBoard({ openSidebar }: JobBoardProps) {
  return (
    <section className="container">
      <div className={'jobsContainer ' + (openSidebar ? 'openSidebar' : '')}>
        <div className="jobBoardLogoContainer">
          <p className="jobBoardTitle">Jobs Listings</p>
          <img
            src="../../../logo_powered_by.png"
            className="mainDashLogo"></img>
        </div>
        <SearchComponent />
      </div>
    </section>
  );
}
