import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import React, { useEffect, useRef, useState } from 'react';

import '../../jobs/job-filters/job-filters.styles.scss';

const FilterLevel = () => {
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [checkedOptions, setCheckedOptions] = useState<string[]>([]);
  const menuRef = useRef<HTMLDivElement>(null);
  const submenuRef = useRef<HTMLDivElement>(null);
  const options = ['admin', 'member'];

  // General useEffect for handling clicks outside the dropdown
  useEffect(() => {
    const handleDocumentClick = (e: MouseEvent) => {
      const menu = menuRef.current as HTMLElement | null;
      if (
        menu &&
        e.target &&
        !(e.target as Element).closest('.userLevelFilter')
      ) {
        setOpenMenu(false);
      }
    };

    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  const handleClear = () => {
    setCheckedOptions([]);
  };

  const toggleOpen = (e: React.MouseEvent<HTMLDivElement>) => {
    const menu = menuRef.current as HTMLElement | null;

    if (menu && e.target && !(e.target as Element).closest('.menuIcon')) {
      const menuPos = menu.getBoundingClientRect();

      if (menuPos && submenuRef.current) {
        submenuRef.current.style.left = menuPos.left + 'px';
      }
      setOpenMenu(!openMenu);
    }
  };

  // logic for updating the list
  const handleCheck = (item: string) => {
    setCheckedOptions((prev) => {
      if (!prev.includes(item)) {
        return [...prev, item];
      } else {
        return prev.filter((checkedItem) => checkedItem !== item);
      }
    });
  };

  return (
    <div className="userLevelFilter">
      <div
        className={'filter-btn users ' + (openMenu ? 'open' : '')}
        onClick={(e) => toggleOpen(e)}
        ref={menuRef}>
        <span className="filter-btn-title">
          {checkedOptions.length === 0
            ? 'Level'
            : 'Level (' + checkedOptions.length + ')'}
        </span>
        <FontAwesomeIcon
          icon={faXmark}
          className={
            'menuIcon ' + (checkedOptions.length !== 0 ? 'active' : '')
          }
          onClick={() => handleClear()}
        />
      </div>
      <div
        className={'filter-items ' + (openMenu ? 'open' : '')}
        ref={submenuRef}>
        <ul className="filterOptions">
          {options.map((item, index) => (
            <li className="filter-item" key={index}>
              <input
                type="checkbox"
                id={`level=${index.toString()}`}
                className="checkInput"
                value={item}
                checked={checkedOptions.includes(item) ? true : false}
                onChange={() => handleCheck(item)}></input>
              <label
                htmlFor={`level=${index.toString()}`}
                className="checkLabel">
                <div className="checkBox">
                  <FontAwesomeIcon icon={faCheck} className="checkIcon" />
                </div>
                <span className={'level ' + item}>{item}</span>
              </label>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default FilterLevel;
