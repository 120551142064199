import * as React from 'react';

const ProfileIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={15}
    height={17}
    viewBox="0 0 15 17"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <circle
      cx={3.54286}
      cy={3.54286}
      r={3.54286}
      transform="matrix(-1 0 0 1 10.7427 1)"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={1.5}
    />
    <path
      d="M1 13.3421C1 12.5801 1.47904 11.9003 2.19668 11.644C5.43215 10.4885 8.96785 10.4885 12.2033 11.644C12.921 11.9003 13.4 12.5801 13.4 13.3421V14.5073C13.4 15.559 12.4685 16.3669 11.4273 16.2182L11.0184 16.1598C8.48565 15.798 5.91435 15.798 3.38162 16.1598L2.97267 16.2182C1.93151 16.3669 1 15.559 1 14.5073V13.3421Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={1.5}
    />
  </svg>
);

export default ProfileIcon;
