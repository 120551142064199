import * as React from 'react';

const LogoutIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 0H6.62924C8.83838 0 10.6292 1.79086 10.6292 4V6.88079L4.90582 6.88079C4.49161 6.88079 4.15582 7.21658 4.15582 7.63079C4.15582 8.045 4.49161 8.38079 4.90582 8.38079L10.6292 8.38079V11.2616C10.6292 13.4707 8.83838 15.2616 6.62924 15.2616H4C1.79086 15.2616 0 13.4707 0 11.2616V10.6831V4.57847V4C0 1.79086 1.79086 0 4 0ZM10.6292 8.38079H13.6322L12.5704 9.37172C12.2676 9.65433 12.2512 10.1289 12.5339 10.4317C12.8165 10.7346 13.2911 10.7509 13.5939 10.4683L15.4686 8.71867C16.1056 8.12417 16.1056 7.13741 15.4686 6.5429L13.5939 4.79325C13.2911 4.51063 12.8165 4.52701 12.5339 4.82983C12.2512 5.13265 12.2676 5.60724 12.5704 5.88986L13.6322 6.88079L10.6292 6.88079V8.38079Z"
    />
  </svg>
);

export default LogoutIcon;
