import React from 'react';
import { Navigate } from 'react-router-dom';

import Spinner from '../components/spinner/spinner.component';
import { AuthContext } from '../services/auth-context.service';
import { useSafeContext } from './useSafeContext';

interface ProtectedRouteProps {
  element: React.ReactElement;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
  const { isAuthenticated, user, isLoading } = useSafeContext(AuthContext);

  if (isLoading) {
    return <Spinner size={25} color={'#bde3d0'} />;
  }

  return isAuthenticated && user ? element : <Navigate to="/login" />;
};

export default ProtectedRoute;
