import * as React from 'react';

const LeftArrowIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={17}
    height={18}
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M1 1L1 17"
      stroke="#777777"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <path
      d="M7.4451 5L4.81149 8.38606C4.53063 8.74717 4.53063 9.25283 4.81149 9.61394L7.4451 13"
      stroke="#777777"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <path
      d="M5.11209 9L16.001 9"
      stroke="#777777"
      strokeWidth={2}
      strokeLinecap="round"
    />
  </svg>
);

export default LeftArrowIcon;
