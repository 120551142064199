import {
  faCircleXmark,
  faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import React, { useRef } from 'react';

import './search-bar.styles.scss';

interface SearchBarProps {
  searchQuery: string;
  onSearchQueryChange: (query: string) => void;
  clearSearch: () => void;
}

const SearchBar: React.FC<SearchBarProps> = ({
  searchQuery,
  onSearchQueryChange,
  clearSearch,
}) => {
  const searchInput = useRef<HTMLInputElement>(null);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearchQueryChange(event.target.value);
  };

  return (
    <div className="searchBarContainer">
      <span className="search-icon">
        <FontAwesomeIcon icon={faMagnifyingGlass} />
      </span>
      <input
        ref={searchInput}
        type="text"
        placeholder="Search"
        value={searchQuery}
        onChange={handleInputChange}
        className="searchBar"
      />
      {searchQuery && (
        <button className="clearButton" onClick={clearSearch}>
          <FontAwesomeIcon icon={faCircleXmark} color="#bde3d0" />
        </button>
      )}
    </div>
  );
};

export default SearchBar;
