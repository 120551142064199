import { doc, getDoc } from 'firebase/firestore';

import firebaseApp from '../../firebaseConfig';
import { UserSchema } from '../../model/zod/user.zod';

export const fetchUserByUID = async (uid: string) => {
  try {
    const userRef = doc(firebaseApp.db, 'users', uid);
    const docSnap = await getDoc(userRef);
    if (docSnap.exists()) {
      const userData = docSnap.data();
      const parsedUser = UserSchema.parse({
        ...userData,
        createdAt: userData.createdAt.toDate(),
      });
      return parsedUser;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};
