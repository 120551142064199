import React from 'react';
import { useNavigate } from 'react-router-dom';

import Careerharvestlogo from '../../assets/Careerharvestlogo.png';
import CloseIcon from '../../assets/CloseIcon';
import DashboardIcon from '../../assets/DashboardIcon';
import HamburgerIcon from '../../assets/HamburgerIcon';
import LeftArrowIcon from '../../assets/LeftArrowIcon';
import LogoutIcon from '../../assets/LogoutIcon';
import ProfileIcon from '../../assets/ProfileIcon';
// import ResourcesIcon from '../../assets/ResourcesIcon'; commented out until resources page is completed
import RightArrowIcon from '../../assets/RightArrowIcon';
import UsersIcon from '../../assets/UsersIcon';
import { AuthContext } from '../../services/auth-context.service';
import { useSafeContext } from '../../util/useSafeContext';
import './sidebar.styles.scss';

type SidebarProps = {
  isOpen: boolean;
  toggleSidebar: () => void;
  currPage: string;
  setCurrPage: (page: string) => void;
};

const Sidebar: React.FC<SidebarProps> = ({
  isOpen,
  toggleSidebar,
  currPage,
  setCurrPage,
}) => {
  const navigate = useNavigate();
  const { user } = useSafeContext(AuthContext);

  // Determine if the user is authorized or if it's development environment
  const isAdminOrDev =
    process.env.NODE_ENV === 'development' || user?.userLevel === 'admin';

  const handleLogout = () => {
    console.log('User logged out');
    localStorage.removeItem('authToken');
    navigate('/login');
  };

  return (
    <>
      <div className={`sidebar ${isOpen ? 'open' : 'closed'}`}>
        <div className="sidebar-header">
          <img
            src={Careerharvestlogo}
            alt="Career Harvest Logo"
            className="sidebar-logo"
          />
          <span className="title">Career Harvest</span>
          <button className="toggle-icon" onClick={toggleSidebar}>
            {!isOpen ? (
              <HamburgerIcon className="hamburger-icon" />
            ) : (
              <CloseIcon className="close-icon" />
            )}
            {isOpen ? (
              <LeftArrowIcon className="arrow-icon" />
            ) : (
              <RightArrowIcon className="arrow-icon" />
            )}
          </button>
        </div>

        <div className="sidebar-content">
          {/* Common Menu Items */}
          <div
            className={`sidebar-item ${currPage === 'dashboard' ? 'active' : ''}`}
            onClick={() => {
              setCurrPage('dashboard');
              navigate('/');
            }}>
            <DashboardIcon className="icon" />
            {isOpen && <span>Dashboard</span>}
          </div>
          {/* Admin Menu Items */}
          {isAdminOrDev && (
            <div
              className={`sidebar-item ${currPage === 'users' ? 'active' : ''}`}
              onClick={() => {
                setCurrPage('users');
                navigate('/users');
              }}>
              <UsersIcon className="icon" />
              {isOpen && <span>Interns</span>}
            </div>
          )}
          {/* Normal User Menu Items */}
          <div
            className={`sidebar-item ${currPage === 'profile' ? 'active' : ''}`}
            onClick={() => {
              setCurrPage('profile'); // Update the current page state
              navigate(`/users/${user?.uid || ''}`); // Navigate to the dynamic profile route
            }}>
            <ProfileIcon className="icon" />
            {isOpen && <span>Profile</span>}
          </div>
          {/* removed until recourses page is completed */}
          {/* <div
            className={`sidebar-item ${currPage === 'resources' ? 'active' : ''}`}
            onClick={() => setCurrPage('resources')}>
            <ResourcesIcon className="icon" />
            {isOpen && <span>Resources</span>}
          </div> */}
        </div>

        <div className="sidebar-footer">
          <div className="logout" onClick={handleLogout}>
            <LogoutIcon className="icon" />
            {isOpen && <span>Logout</span>}
          </div>
        </div>
      </div>

      {/* Overlay */}
      <div
        className={`overlay ${isOpen ? 'visible' : ''}`}
        onClick={toggleSidebar}></div>
    </>
  );
};

export default Sidebar;
