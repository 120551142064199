import * as React from 'react';

const RightArrowIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={17}
    height={18}
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M16 17L16 1"
      stroke="#777777"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <path
      d="M9.55539 13L12.189 9.61394C12.4699 9.25283 12.4699 8.74717 12.189 8.38606L9.55539 5"
      stroke="#777777"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <path
      d="M11.8889 9L1 9"
      stroke="#777777"
      strokeWidth={2}
      strokeLinecap="round"
    />
  </svg>
);

export default RightArrowIcon;
