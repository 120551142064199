import React, { useEffect, useState } from 'react';

import { fetchJobsByUid, fetchSavedJobs } from '../../../api/firebase/service';
import { SavedJob } from '../../../model/zod/jobs.zod';
import { AuthContext } from '../../../services/auth-context.service';
import { encodeJobId } from '../../../util/codec';
import { useSafeContext } from '../../../util/useSafeContext';
import ServerSidedPagination from '../../pagination/server-sided-pagination.component';
import JobListing from '../job-listing/job-listing.component';
import './saved-jobs.styles.scss';

interface SavedJobsProps {
  uid?: string;
}

export default function SavedJobs({ uid }: SavedJobsProps) {
  const { user } = useSafeContext(AuthContext);
  const [sortedJobs, setSortedJobs] = useState<string[]>([]);

  // Reverse the saved jobs so that the latest saved jobs are shown first
  useEffect(() => {
    const fetchJobs = async () => {
      const id = uid ?? user?.uid;
      if (id) {
        const jobs = await fetchJobsByUid(id, 'savedJobs');
        // Reverse the saved jobs so that the latest saved jobs are shown first
        setSortedJobs(jobs.reverse());
      }
    };

    fetchJobs();
  }, [uid, user]);

  const renderJob = (job: SavedJob) => (
    <JobListing key={encodeJobId(job.id)} job={job} uid={uid ?? user?.uid} />
  );

  return (
    <section className="savedContainer">
      <img
        src="../../../logo_powered_by.png"
        className="mainSavedJobsLogo"></img>
      <div className="starredList">
        <ServerSidedPagination
          ids={sortedJobs}
          queryKey="saved_jobs"
          renderItem={renderJob}
          fetchData={fetchSavedJobs}
        />
      </div>
    </section>
  );
}
