import React, { createContext } from 'react';

interface LoggerContextInterface {
  log: (m: string) => void;
  track: (event: string, payload?: Record<string, string>) => void;
  warn: (m: string) => void;
  error: (code: string, m: unknown, payload?: Record<string, string>) => void;
}

interface Props {
  children: JSX.Element;
}

export const LoggerContext = createContext<LoggerContextInterface | undefined>(
  undefined,
);

export const LoggerProvider = ({ children }: Props) => {
  const log = (m: string) => {
    console.log(m); // eslint-disable-line no-console
  };

  const track = (event: string, payload?: Record<string, string>) => {
    console.trace(event, payload ?? {}); // eslint-disable-line no-console
  };

  const warn = (m: string) => {
    console.warn(m); // eslint-disable-line no-console
  };

  const error = (
    code: string,
    m: unknown,
    payload?: Record<string, unknown>,
  ) => {
    console.error(`${code}:`, m, payload ?? {}); // eslint-disable-line no-console
  };

  return (
    <LoggerContext.Provider
      value={{
        log,
        track,
        warn,
        error,
      }}>
      {children}
    </LoggerContext.Provider>
  );
};
