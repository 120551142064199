// TEMP FIX: function to remove the '//' from segments for job ids

// Encodes a string to a base64 string, replacing '/' with '_' for URL compatibility
export function encodeJobId(originalId: string): string {
  const base64 = btoa(originalId);
  const encodedSegment = base64.replace(/\//g, '_');
  return encodedSegment;
}

// Decodes a base64 string to a regular string, replacing '_' with '/' to correct the base64 string
export function decodeJobId(encodedId: string): string {
  const base64 = encodedId.replace(/_/g, '/');
  const originalSegment = atob(base64);
  return originalSegment;
}
