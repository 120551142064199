import * as React from 'react';

const DashboardIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={15}
    viewBox="0 0 16 15"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.73839 0L8.73839 15H4.27907C1.9158 15 0 13.2039 0 10.9884V4.01163C0 1.79607 1.9158 0 4.27907 0H8.73839ZM11.7209 15H10.2384V9.64536H16V10.9884C16 13.2039 14.0842 15 11.7209 15ZM16 8.14536H10.2384L10.2384 0H11.7209C14.0842 0 16 1.79607 16 4.01163V8.14536Z"
    />
  </svg>
);

export default DashboardIcon;
