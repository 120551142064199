import React from 'react';

import '../../jobs/job-filters/job-filters.styles.scss';
import FilterLevel from './filter-level.component';
import './user-filters.styles.scss';

const UserFilters = () => {
  return (
    <div className="userFilters">
      <FilterLevel />
    </div>
  );
};

export default UserFilters;
