import React, { useEffect, useMemo, useRef, useState } from 'react';

import './pagination.styles.scss';

interface PaginationProps<T> {
  queryResults: T[];
  renderItem: (item: T, index: number) => JSX.Element;
  itemsPerPage?: number;
  pagesPerGroup?: number;
  onScroll: (ref: React.RefObject<HTMLDivElement>) => void;
  scrolled: boolean;
  scope?: string;
}

const Pagination = <T,>({
  queryResults,
  renderItem,
  itemsPerPage = 15,
  pagesPerGroup = 3,
  onScroll,
  scrolled,
  scope = '',
}: PaginationProps<T>) => {
  const [currentPage, setCurrentPage] = useState(1);
  const topRef = useRef<HTMLDivElement>(null);
  const sectionRef = useRef<HTMLDivElement>(null);
  // Resets the current page when the query results change
  useEffect(() => {
    setCurrentPage(1);
  }, [queryResults]);

  // Every time page changes, scroll to top
  useEffect(() => {
    topRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [currentPage]);

  const totalPages = useMemo(() => {
    return Math.ceil(queryResults.length / itemsPerPage);
  }, [queryResults, itemsPerPage]);

  const currentItems = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return queryResults.slice(startIndex, endIndex);
  }, [currentPage, queryResults, itemsPerPage]);

  const renderResults = useMemo(() => {
    return currentItems.map(renderItem);
  }, [currentItems]);

  const handleNext = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrev = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handlePageClick = (page: number) => {
    setCurrentPage(page);
  };

  // Handle scroll events in dashboard
  const handleScroll = () => {
    onScroll(sectionRef);
  };

  useEffect(() => {
    const section = sectionRef.current;
    if (section) {
      section.addEventListener('scroll', handleScroll);

      return () => {
        section.removeEventListener('scroll', handleScroll);
      };
    }
    return;
  }, []);

  const renderPageNumbers = () => {
    const pages = [];
    const currentGroup = Math.ceil(currentPage / pagesPerGroup);

    const startPage = (currentGroup - 1) * pagesPerGroup + 1;
    const endPage = Math.min(startPage + pagesPerGroup - 1, totalPages);

    if (startPage > 1) {
      pages.push(
        <button
          key="prevDots"
          className="paginationEllipsis"
          onClick={() => handlePageClick(startPage - 1)}>
          ...
        </button>,
      );
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          className={`paginationButton ${i === currentPage ? 'active' : ''}`}
          onClick={() => handlePageClick(i)}
          disabled={i === currentPage}>
          {i}
        </button>,
      );
    }

    if (endPage < totalPages) {
      pages.push(
        <button
          key="nextDots"
          className="paginationEllipsis"
          onClick={() => handlePageClick(endPage + 1)}>
          ...
        </button>,
      );
    }

    return pages;
  };

  const renderPaginations = () => {
    return (
      <div className="paginationControls">
        <button
          onClick={handlePrev}
          disabled={currentPage === 1}
          className="paginationButton">
          Prev
        </button>
        {renderPageNumbers()}
        <button
          onClick={handleNext}
          disabled={currentPage === totalPages}
          className="paginationButton">
          Next
        </button>
      </div>
    );
  };

  return (
    <div
      className={'paginationContainer ' + (scrolled ? 'scrolled' : '')}
      ref={sectionRef}>
      {queryResults.length > 0 && (
        <>
          <div className={'queryList ' + scope} ref={topRef}>
            {renderResults}
          </div>
          {renderPaginations()}
        </>
      )}
    </div>
  );
};

export default Pagination;
