import { z } from 'zod';

const DescriptionSchema = z.record(z.string());

export const SavedJobSchema = z.object({
  display: z.string(),
  title: z.string(),
  company: z.string(),
  location: z.string(),
  pay: z.string(),
  description: z.array(DescriptionSchema),
  typeOfWork: z.string(),
  timeCommitment: z.string(),
  link: z.string(),
  id: z.string(),
  jobCategory: z.string(),
  savedAt: z.date(),
});

export type SavedJob = z.infer<typeof SavedJobSchema>;
