import React from 'react';

interface HamburgerIconProps {
  className?: string;
}

const HamburgerIcon: React.FC<HamburgerIconProps> = ({ className }) => (
  <svg
    className={className}
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_341_957)">
      <rect x="3" y="2" width="50" height="50" rx="7" fill="white" />
    </g>
    <rect
      x="17"
      y="18"
      width="23"
      height="3.10345"
      rx="1.55172"
      fill="#171717"
    />
    <rect
      x="17"
      y="25.4482"
      width="23"
      height="3.10345"
      rx="1.55172"
      fill="#171717"
    />
    <rect
      x="17"
      y="32.8965"
      width="23"
      height="3.10345"
      rx="1.55172"
      fill="#171717"
    />
    <defs>
      <filter
        id="filter0_d_341_957"
        x="0"
        y="0"
        width="56"
        height="56"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_341_957"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_341_957"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default HamburgerIcon;
