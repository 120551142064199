import React from 'react';

import './spinner.styles.scss';

type Props = {
  size: number;
  color: string;
};

const Spinner = ({ ...props }: Props) => {
  return (
    <div className="spinner-container">
      <span
        className="spinner"
        style={{
          width: props.size,
          height: props.size,
          borderWidth: props.size / 8,
          borderTopColor: props.color,
        }}></span>
    </div>
  );
};

export default Spinner;
